import React from "react"
import styled from "styled-components"
import { Data } from "../../data.json"
import { Link } from "gatsby"
import CenteredText from "../components/CenteredText"
import Dots from "../components/Dots"
import Default from "../layouts/default"
import { spacers, breakpoints, colors } from "../styles/styles"

const ListenDiv = styled.div``
const ListenItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacers.s}px;

  &:hover {
    background: ${colors.hovermain};
    cursor: pointer;
  }

  > div:first-of-type {
    order: 2;
  }

  > div:nth-of-type(2) {
    order: 1;
    margin-bottom: ${spacers.s}px;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-column-gap: ${spacers.m}px;
    padding: ${spacers.m}px;

    > div:first-of-type {
      order: 1;
    }

    > div:nth-of-type(2) {
      order: 2;
      margin-bottom: 0;
      img {
        object-fit: cover;
        height: 227px;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    grid-column-gap: ${spacers.l}px;
    padding: ${spacers.m}px ${spacers.l}px;
  }

  > div:first-of-type {
    margin: auto 0;

    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: ${spacers.s}px;
      margin-bottom: ${spacers.s}px;
    }
  }
`

const PageInfo = {
  desc:
    "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
  keywords:
    "übernachtung, pension, erzgebirge, zimmer, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer, zweibettzimmer",
  title: 'Zimmer und Ferienwohnungen – Pension "Zur Altdeutschen"',
}

const ZimmerUndFewo = () => (
  <Default
    pageInfo={PageInfo}
    headerImg="/assets/img/IMG_20131209_120147.jpg"
    isActive="zimmer"
  >
    <ListenDiv>
      {Data.map(item => (
        <Link key={item.name} to={"/" + item.slug}>
          <ListenItem>
            <div>
              <h2>{item.name}</h2>
              <p dangerouslySetInnerHTML={{ __html: item.blurb }}></p>
              {item.features.map(feature => (
                <img key={feature.icon} src={feature.icon} alt="Icon" />
              ))}
            </div>
            <div>
              <img src={item.contentImg} alt={`Bild ${item.name}`} />
            </div>
          </ListenItem>
        </Link>
      ))}
    </ListenDiv>

    <CenteredText>
      <p>
        Aufbettung für Doppelzimmer und FeWo € 20,-/Nacht <br />
        Kinder bis 4 Jahre im Bett der Eltern frei, 5-12 Jahre 50% Ermäßigung
        <br />
        Kinderbett auf Anfrage
        <br />
        Haustiere sind willkommen für € 5,-/Nacht
        <br />
        Bettwäsche, Handtücher und Endreinigung <br />
        inklusive kostenfreies WLAN <br />
        Parkmöglichkeit in der Nähe Abstellmöglichkeit für Motorräder und
        Fahrräder im Innenhof <br />
        Zimmerservice während des Aufenthalts
        <br />
      </p>
      <Dots />
      <p>
        Hinweis bei nur einer Übernachtung: Zuschlag für Mehraufwand € 5.– pro
        Person
      </p>
    </CenteredText>
  </Default>
)

export default ZimmerUndFewo
